
.cke_contents {
    width: 252mm !important;
    margin: auto !important;
    border-left: 1px dashed #999 !important;
    border-right: 1px dashed #999 !important;

}

.cke_inner {
    background: #fff !important;
}

.cke_editable {
    margin: 0px !important;
}

@media print {

    @page  {
        margin: 125mm;
    }

    .editor {

    }

}
