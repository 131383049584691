.chat-conversation-window {
    bottom: 0;
    position: fixed;
    width: 300px;
    background: white;   
    transition: max-height 0.2s ease;
    border: 1px solid #00a65a;
    z-index: 1000;
}

.chat-conversation-window.expanded {
    max-height: 400px;
}

.chat-conversation-window.collapsed {
    max-height: 3em;
}
    
.chat-conversation-window > .chat-title-box {
    min-height: 3em;
    background: #00a65a;
    color: white;
    font-weight: bold;
    line-height: 3em;
    padding-left: 20px;
    padding-right: 20px;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
}

.chat-conversation-window > .chat-title-box > .close-button {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 3em;
    font-size: 3em;
}

.chat-conversation-window > .chat-text-box {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.chat-conversation-window > .chat-text-box > textarea {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.chat-conversation-window > .chat-text-box > button {
    width: 100%;
    background: #00a65a;
    color: white;
    font-weight: bold;
    text-align: center;
    display: block;
    font-size:1em;
    line-height: 1.5em;
    padding: 10px;
}


.chat-list-window{
    cursor: pointer;
    bottom:0;
    position:fixed;
    right: 20px;
    float:right;
    margin-left:10px;
    background: white;   
    transition: max-height 0.2s ease;
    border: 1px solid #00a65a;

}

.chat-list-window.expanded {
    max-height: 400px;
}

.chat-list-window.collapsed {
    max-height: 3em;
}


.chat-list-window > .chat-search-box > input {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

}

.chat-list-window > .chat-search-box > input:before {
    content: "&#x1F50D";
}

.chat-list-window > .chat-title-box {
    min-height: 3em;
    background: #00a65a;
    color: white;
    font-weight: bold;
    line-height: 3em;
    padding-left: 20px;
    padding-right: 20px;
    width: 300px;
}

.chat-list-window > .chat-list-entries {
    padding: 10px;
    overflow-y: auto;
    height: 300px;
}

.chat-list-window li {
    padding-bottom: 5px;
    padding-top: 5px;
    list-style: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width:260px;
}

.chat-list-window li > .chat-item {
    color: #ddd;
}

.chat-list-window .chat-item-hint {
    width: 260px;
    display: block;
    padding: 10px;
}

.chat-message-list-entries {
    min-height: 200px;
    padding: 20px;
    max-height: 300px;
    margin-bottom: 120px!important;
    overflow-y: scroll;
}

.chat-message-list-entries li {
    border: 1px solid #00a65a21;
    
    list-style: none;
    padding: 10px;
    margin-bottom: 5px;
}

li.mine {
    background: #dcffe4;
    border-radius: 10px 10px 0px 10px;
    margin-left: 30px;
}

li.others {
    background: #f8fffa;
    border-radius: 10px 10px 10px 0px;
    margin-right: 30px;
}

li.mine .message-time {
    font-size: 0.9em;
    margin-top: 1em;
    display: block;
    text-align: right;
}

li.others .message-time {
    font-size: 0.9em;
    margin-top: 1em;
    display: block;
    text-align: left;
}
